import AboutUsPerson from "./AboutUsPerson"

export default function AboutUsPersons() {
    const myArray = [{
        name: "Jeroen",
        lastName: "Jansen",
        image: "assets/img/blog/tp-2.png",
        description: "blablalbalbalbalb"
    },
    {
        name: "Jeroen",
        lastName:  "Jansen",
        image: "assets/img/blog/tp-2.png",
        description: "blablalbalbalbalb"
    }];
    return (
        <>
            <section className="tour-date-area section-padding bg-lightGray">
                <div className="container">
                    {myArray.map((item, index) => {
                        return <AboutUsPerson key={index} index={index} {...item} />
                    }
                    )}
                </div>
            </section>
        </>
    )
}