import PageTitle from '../components/PageTitle';
import AboutUsFamily from '../components/AboutUsFamily';
import AboutUsPersons from '../components/AboutUsPersons';
export default function AboutUs() {

    return (
        <>
            <PageTitle title="Over ons" image='over-ons/IMG_3177.jpeg'/>
            <AboutUsFamily />
            <AboutUsPersons />
            

        </>
    )
}
