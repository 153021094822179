export default function AboutUsPerson({ index, name, lastName, description, image }) {
    function isEven(number) {
        return number % 2 === 0;
    }
    return (
        <>
            <div className="row">
                {isEven(index) ? <PersonInfo isEven={isEven(index)} name={name} lastName={lastName} description={description} /> : <PersonPicture isEven={isEven(index)} name={name} image={image} />}
                {isEven(index) ? <PersonPicture isEven={isEven(index)} name={name} image={image} /> : <PersonInfo isEven={isEven(index)} name={name} lastName={lastName} description={description} />}

            </div>
        </>
    )
}

function PersonInfo({ isEven, name, lastName, description }) {
    return (
        <div className="col-md-6">
            <div className={isEven ? "section-title" : "section-title st-from-right"}>
                <h3><span>{name}</span><br />{lastName}</h3>
            </div>
            <div className="section-paragraph">
                <p>{description}</p>
            </div>
        </div>
    )
}

function PersonPicture({ name, image = "assets/img/blog/tp-2.png" }) {
    return (
        <div className="col-md-6">
            <div className="section-img">
                <div className="about-me-img">
                    <img src={image} alt={`Foto van ${name}`} />
                </div>
            </div>
        </div>
    )
}