import menu from '../data/menu.json';
import { Link } from 'react-router-dom';
function Header() {


    return (
        <header id="header" className="header-area headerV1" style={{ backgroundColor: '#a8ab5c' }}>
            <nav className="navbar navbar-expand-lg footstep-nav footstep-nav-v1">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/"><img src="/kwibus-logo/png/logo-no-background-black.png" alt="" className="logo" style={{width:"160px"}}/></Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fa fa-bars" aria-hidden="true" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            {menu.filter(i => i.active).map((item, index) =>
                                <li key={`menu-item-${index}`} className="nav-item">
                                    <Link className="nav-link" to={item['url']}>{item['title']}</Link>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </nav>
        </header>


    )
}

export default Header;