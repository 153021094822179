import { Link } from 'react-router-dom';
export default function BlogOverviewItem(children) {
    const { title, headline, date, campsite, image, location, size = "large",slug } = children;

    const divSize = size === "large" ? "col-md-12" : "col-md-4";
    const imgUrl = `/images/${size}/${image}`;
    return (
        <div className={divSize}>
            <Link to={`/blog/${slug}`}><div className="single-tat stat-v2 stat-fullwidth">
                <div className="stat-img">
                    <img src={imgUrl} alt="" />
                </div>
                <div className="stat-text">
                    <div className="stat-meta">
                        <p><span className="sm-date">{date}</span><span className="sm-category"></span></p>
                    </div>
                    <h4>{title}</h4>
                    <p>{headline}</p>
                    <div className="stat-location-comment">
                        <ul>
                            <li className="slocation">
                                <i className="fa fa-map-marker" aria-hidden="true" />{location}
                            </li>
                            <li className="slocation">
                                <i className="fa fa-flag" aria-hidden="true" />{campsite}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            </Link>
        </div>
    )
}
