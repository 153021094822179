export default function PageTitle({ title, image = "blog_default.jpg" }) {
    return (
        <section className="innerpage-title-area travel-tips-title" style={{ backgroundImage: `linear-gradient(rgba(203, 206, 118, 0.6), rgba(165, 168, 92, 0.6)), url(/images/header/${image})` }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="innerpage-titile">
                            <h2>{title}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}