import { Link } from 'react-router-dom';
export default function SmallIntro() {
    return (
        <section id="about" className="about-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="section-title">
                            <h3><span>Kwibus</span> Ons caravan avonturen!</h3>
                        </div>
                        <div className="section-paragraph">
                            <p>Reis mee met onze chaotische, maar liefdevolle bende van vijf kinderen en een hond, terwijl we Europa verkennen in onze caravan. Ontdek avontuur en gezinsplezier!</p>
                        </div>
                        <div className="s-text-btn">
                            <Link to="/over-ons" >Leer meer over ons <i className="fa fa-angle-right" aria-hidden="true" /></Link>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="section-img">
                            <div className="about-me-img">
                                <img src="images/site/about_us.jpg" alt="caravan an family picture" style={{borderRadius: "2%"}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}