import BlogOverviewSection from "../components/BlogOverviewSection";
import SmallIntro from "../components/SmallIntro";
export default function Home() {
    return (
        <>
            <SmallIntro />
            <BlogOverviewSection />

        </>
    );
}