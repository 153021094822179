import BlogOverviewItem from "./BlogOverviewItem"
import blogs from "../data/blogs.json"
export default function BlogOverviewSection() {
    return (
        <section id="blog-post3" className="blog-postV3 section-padding bg-dark-blue">
            <div className="container">
                <div className="row">
                    {blogs.sort((a,b) => a.id < b.id).map((blog, index) =>
                        <BlogOverviewItem key={index} size={index === 0 ? "large" : "small"} {...blog} />)}
                </div>

            </div>
        </section>
    )
}