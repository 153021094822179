export default function Footer() {
    return (
        <footer className="footer-area">
            <div className="footer-widget">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="fw-left">
                                <div className="footer-logo">
                                    <img src="/kwibus-logo/png/logo-no-background-black.png" alt="" />
                                </div>
                                <div className="footer-text">
                                    <p>Reis mee met onze chaotische, maar liefdevolle bende van vijf kinderen en een hond, terwijl we Europa verkennen in onze caravan. Ontdek avontuur en gezinsplezier! </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-4">
                                    {/* <div className="fw-single footer-contact">
                                        <h4>Contact Me</h4>
                                        <ul className="fws">
                                            <li><span>Add:</span> 221B Baker St <br />London, NW1 6XE <br />UK</li>
                                            <li><span>T:</span> +010 222 9999</li>
                                            <li><span>E:</span> info@footsteps.com</li>
                                            <li><span>W:</span>  www.footsteps.com</li>
                                        </ul>
                                    </div> */}
                                </div>
                                <div className="col-md-4">
                                    {/* <div className="fw-single footer-links">
                                        <h4>Account</h4>
                                        <ul className="fws">
                                            <li><a href="#">My Lists</a></li>
                                            <li><a href="#">My Requests</a></li>
                                            <li><a href="#">My Credits</a></li>
                                            <li><a href="#">My Info</a></li>
                                            <li><a href="#">Contact</a></li>
                                        </ul>
                                    </div> */}
                                </div>
                                <div className="col-md-4">
                                    {/* <div className="fw-single">
                                        <h4>Travel</h4>
                                        <ul className="fws footer-links">
                                            <li><a href="#">Travel</a></li>
                                            <li><a href="#">Why Travel</a></li>
                                            <li><a href="#">Become a Traveler</a></li>
                                            <li><a href="#">How Its Works</a></li>
                                            <li><a href="#">Traveling FAQs</a></li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="fc-left">
                                <ul className="fc-social">
                                    {/* <li><a href="#"><i className="fa fa-facebook" aria-hidden="true" /></a></li>
                                    <li><a href="#"><i className="fa fa-twitter" aria-hidden="true" /></a></li>
                                    <li><a href="#"><i className="fa fa-google-plus" aria-hidden="true" /></a></li>
                                    <li><a href="#"><i className="fa fa-linkedin" aria-hidden="true" /></a></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="fc-right">
                                <ul>
                                    <li><p>© 2023 Kwibus. All rights reserved</p></li>
                                    {/* <li><a href="#">Terms</a></li>
                                    <li><a href="#">Privacy</a></li>
                                    <li><a href="#">Site Map</a></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    )
}