export default function BlogItemText({title, paragraphs, image}) {
    return (
        <section className="tourPlanning-welcome section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tp-welcome">
                            <div className="tpw-img-text">
                                {image && <img src={`/images/jumbo/${image}`} alt="" style={{borderRadius: "2%"}}/>}
                                {title && <h5>{title}</h5>}
                                {paragraphs.map((item, index) => <p>{item.paragraph}</p>)}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}