import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';

const Gallery = ({ images }) => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  const openLightbox = (index) => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: index,
    });
  };

  return (
    <section className="tour-date-area section-padding bg-lightGray">
      <div className="container">
        <div className="row">
          {images.map((item, index) => (
            <div key={index} className="col-md-4" style={{ marginTop: '20px' }}>
              <div className="about-me-img">
                <img
                  src={`/images/rectangle/${item.image}`}
                  alt={item.caption}
                  onClick={() => openLightbox(index + 1)}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <FsLightbox
        toggler={lightboxController.toggler}
        sources={images.map((item) => `/images/original/${item.image}`)}
        slide={lightboxController.slide}
        captions={images.map((item) => item.caption)}
      />
    </section>
  );
};

export default Gallery;
