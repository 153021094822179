import { useParams } from 'react-router-dom';
import blogs from '../data/blogs';
import Error from './Error';
import PageTitle from '../components/PageTitle';
import BlogItemText from '../components/BlogItemText';
import BlogItemImages from '../components/BlogItemImages';
export default function Blog() {
    const { slug } = useParams();
    const blog = blogs.find((blog) => blog.slug === slug);
    const { title, image, details } = blog;
    const { jumbo, images } = details;

    if (blog === undefined) {
        return (
            <Error />
        )
    }

    return (<>
        <PageTitle title={title} image={image} />
        <BlogItemText title={title} paragraphs={jumbo.content} image={jumbo.image} />
        <BlogItemImages images={images} />



    </>
    )
}
