export default function AboutUsFamily() {

    return (
        <>
            <section className="tourPlanning-welcome section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tp-welcome">
                                <div className="tpw-title">
                                    <h4>Welcome to Footsteps Egypt Adventure Tour</h4>
                                </div>
                                <div className="tpw-img-text">
                                    <img src="/images/header/over-ons/IMG_2287.jpeg" alt="" />
                                    <h5>Welcome to Cairo, Egypt!</h5>
                                    <p>Footsteps Egypt tour are personalized, small group tours tailored to those of you who have always dreamed of visiting Egypt but never really knew where to begin when it comes to all of the planning and logistics involved.After spending the last few years traveling to Egypt and building deep connections on the ground there, I’ve managed to turn my dreams of running tours in Egypt into a reality. </p>
                                    <p>Fortunately for you, you no longer have to worry about all of the hassles that come along with planning your next trip to Egypt — simply join me on one of my tours and you can rest assured that you’ll be able to experience a trip of a lifetime without ever having to book a single hotel, activity, excursion or domestic flight within Egypt.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}