import { Link } from 'react-router-dom';
export default function NotFound() {
    return (
        <section className="error-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="error-content">
                            <div className="ec-img">
                                <img src="/assets/img/bg/404.png" alt="" />
                            </div>
                            <div className="ec-text">
                                <h3>Oei! Pagina niet gevonden</h3>
                                <p>Sorry, maar de pagina waarnaar je op zoek bent bestaat niet, is verwijderd,
                                    van naam veranderd of is tijdelijk niet beschikbaar.</p>
                                <Link to="/" className="btn-style-a">Terug naar start</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}